import { useRef, useState } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { Float, useGLTF, Effects } from '@react-three/drei'
import * as THREE from 'three';
import { BloomPass } from 'three/examples/jsm/Addons.js';
import { GlitchPass } from 'three/examples/jsm/Addons.js';
import { OutlinePass } from 'three/examples/jsm/Addons.js';
extend({ OutlinePass, BloomPass, GlitchPass });

const Ring = (props) => {
    const ref = useRef();
    const { nodes, materials } = useGLTF('/obso.glb');
    const [opacity, setOpacity] = useState(0);

    const flickerOpacity = () => {
        if (Math.random() > 0.9) { // Adjust the probability as needed
            setOpacity(Math.random() * 0.7); // Random opacity between 0 and 0.7
        }
    };

    useFrame(({ clock }) => {
        if (opacity < 0.7) setOpacity(opacity + 0.001);
        flickerOpacity();
    });

    return (
        <group {...props} dispose={null}>
            <Float>
                <mesh
                    ref={ref}
                    castShadow
                    receiveShadow
                    geometry={nodes['object-0'].geometry}
                    position={[0, 0.5, -2]} 
                    rotation={[Math.PI / 2, Math.PI / 2, 0]}
                    scale={[150, 100, 150]}>
                    <meshStandardMaterial
                        color="#bada55"
                        metalness={1}
                        roughness={0}
                        envMapIntensity={1}
                        transparent={true}
                        opacity={opacity}
                    />
                </mesh>
            </Float>
        </group>
    );
};

useGLTF.preload('/obso.glb');

const ThreeD = () => {
    return (
        <div style={{ position: 'fixed', zIndex: 10, pointerEvents: 'none', top: 0, left: 0, width: '100vw', height: '100vh' }}>
            <Canvas style={{ pointerEvents: 'none' }}>
                <Effects>
                    {/* <bloomPass attachArray='passes'/> */}
                    {/* <outlinePass/> */}
                    {/* <glitchPass attachArray='passes' goWild={false} curF={0} randX={10}/> */}
                </Effects>
                {/* <ambientLight color={'white'} intensity={100} /> */}
                <pointLight position={[-50, -20, 50]} decay={0} intensity={50} />
                <pointLight position={[50, -20, 50]} decay={0} intensity={50} />
                <pointLight position={[0, 10, 10]} decay={0} intensity={50} />
                <Ring />
            </Canvas>
        </div>
    );
};

export default ThreeD;