import React, { useEffect, useRef } from 'react';

import Content from './Content';
import AsciiBackground from './AsciiBackground';
import ThreeD from './ThreeD';

import './App.css';

import {
    rando
} from './utils';

const titleMain = '_(´○｀)_\u205f​​​';
const titleAlt = '_(´w｀)_\u205f';
const titleAnim = [
    '',
    '',
    '',
    '',
    '',
    'z',
    'Zz',
    'zZz',
    'ZzZz',
    'zZzZz',
    '\u205f​​​zZzZ',
    '\u205f​​​\u205f​​​zZz',
    '\u205f​​​\u205f​​​\u205f​​​zZ',
    '\u205f​​​\u205f​​​\u205f​​​\u205f​​​z',
    '',
    '',
    '',
    '',
    '',
    '7',
    '37',
    '337',
    '1337',
    'o1337',
    'so1337',
    'bso1337',
    'obso1337',
    '\u205f​​​obso1337',
    '\u205f\u205f​​obso133',
    '\u205f\u205f\u205f​​​obso13',
    '\u205f\u205f\u205f\u205f​​obso1',
    '\u205f\u205f\u205f\u205f​​​\u205fobso',
    '\u205f\u205f\u205f\u205f​​​\u205f\u205fobs',
    '\u205f\u205f\u205f\u205f​​​\u205f\u205f\u205fob',
    '\u205f\u205f\u205f\u205f​​​\u205f\u205f\u205f\u205fo',
];

function App() {
    const titleAnimIndex = useRef(0);

    const setVhVal = () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    const animateTitle = () => {
        document.title = `${rando(1, 100) >= 95 ? titleAlt : titleMain}${titleAnim[titleAnimIndex.current]}`;

        titleAnimIndex.current++;
        if (titleAnimIndex.current > titleAnim.length - 1) {
            titleAnimIndex.current = 0;
        };
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        setVhVal();

        let animTitleIntervalRef = setInterval(animateTitle, 300);

        return () => {
            clearInterval(animTitleIntervalRef);
        };
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div id="app">
            <AsciiBackground
                sectionSizes={{
                    480: 14,
                    768: 14,
                    1024: 14,
                    1200: 14,
                    1500: 14,
                    2500: 15,
                    'ub3r': 25
                }}
                fontSizes={{
                    480: 9,
                    768: 9,
                    1024: 10,
                    1200: 10,
                    1500: 10,
                    2500: 11,
                    'ub3r': 18
                }}
                stringToAnimate={'obso1337'}
                defaultColour={[186, 218, 85]}
                secondaryColour={[40, 40, 50]}
                maxOpacity={1}
                matrixSectionBorderOnly={true}
                matrixSectionBorderWidth={1}
                matrixSectionMargin={3}
                yingYangInterval={150}
                nonCharacters={['$', '£', '@', '*', 1, 3, 3, 7]}
                nonCharacterWhitespaceChance={50}
                characterAnimationInterval={300}
                characterWaveWidth={35}
                characterWaveWhiteSpaceAtEdgeChance={30}
                characterWaveWhiteSpaceAtEdgeCutoff={0.5}
                characterMinOpacity={0.3}
                animationInterval={25}
                animationStartAndEndBuffer={200}
            />
            <Content />
            <ThreeD />
        </div>
    );
}

export default App;
